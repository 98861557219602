import { PageProps } from "gatsby";
import React from "react";
import { WithAuth } from "../../../components/molecules/Auth/WithAuth";
import { AdminJobDetailsPage } from "../../../components/pages/AdminPage/AdminJobDetailsPage/AdminJobDetailsPage";

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const jobId = params?.jobId ?? "";
    return (
        <WithAuth adminOnly={true}>
            <AdminJobDetailsPage jobId={jobId} />
        </WithAuth>
    );
};

export default Page;
