import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

interface IResponse {
    readonly files: Array<string>;
}

const getJobGallery = async (config: AxiosRequestConfig, jobId: string) => {
    const uri = `${Constants.ApiRoot}/job/${jobId}/gallery`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data;
};

export const useJobGallery = (jobId: string) => {
    const { authToken } = useAuth({ allowAnonymous: true });
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Job, QueryCacheKey.JobGallery, jobId], () => getJobGallery(config, jobId));
};
