import * as React from "react";
import { useTranslation } from "react-i18next";
import { useJobGallery } from "../../../hooks/api/job/useJobGallery";
import { H1 } from "../../atoms/H1/H1";
import { JobImage } from "../JobImage/JobImage";
import { GalleryContainer } from "./Gallery.styles";

interface IProps {
    readonly jobId: string;
}

export const Gallery: React.FC<IProps> = ({ jobId }) => {
    const [t] = useTranslation();
    const jobGalleryData = useJobGallery(jobId);

    if (jobGalleryData.isLoading) {
        return <>Loading</>;
    }
    const images = jobGalleryData.data?.files.map((imageId) => {
        return <JobImage jobId={jobId} imageId={imageId} key={imageId} width={200} height={200} />;
    });
    return (
        <>
            <H1> {t("jobDetailsPage.gallery")}</H1>
            <GalleryContainer>{images}</GalleryContainer>
        </>
    );
};
