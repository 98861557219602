import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

const getJobOutput = async (config: AxiosRequestConfig, jobId: string, imageId: string | null, dimensions?: IDimensions) => {
    if (imageId === null) {
        return null;
    }
    const dimensionsRecord = (dimensions ?? {}) as unknown as Record<string, string>;
    const queryParams = new URLSearchParams(dimensionsRecord);
    const queryParamString = queryParams.toString();
    const uri = `${Constants.ApiRoot}/job/${jobId}/scaled-file/${imageId}?${queryParamString}`;
    const { data } = await axios.get<string>(uri, config);
    return data;
};

interface IDimensions {
    readonly width: number;
    readonly height: number;
}

export const useJobOutput = (jobId: string, imageId: string | null, enabled: boolean, dimensions?: IDimensions) => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Job, jobId, imageId, dimensions], () => getJobOutput(config, jobId, imageId, dimensions), { enabled });
};
